import { mapGetters } from "vuex";
import { auth } from "@/services/auth";
import {
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier
  } from 'firebase/auth'

import { isEmpty, maskPhoneNumber } from "@/utils";
import { AUTH_ERROR_CASE_MESSAGES } from "@/constants/app";

/**
 * Mfa authenticator utility mixin
 */
export default {
  name: "MfaAuthenticatorMixin",
  /**
  |--------------------------------------------------
  | Data Properties
  |--------------------------------------------------
  */
  data() {
    return {
      otp: "",
      isLoading: false,
      isMfaEnabled: false,
      smsVerificationId: "",
      errorMessage: "",
      authResolver: null,
      recaptchaVerifier: null,
      recaptchaVerified: false,
      isRecaptchaRendered: false,
      recaptchaContainer: "recaptcha-containers",
    };
  },
  /**
  |--------------------------------------------------
  | Computed properties
  |--------------------------------------------------
  */
  computed: {
    ...mapGetters({
      primaryMfaDetail: "auth/primaryMfaDetail",
      multiFactorDetails: "auth/primaryMfaDetail",
    }),
    /**
     * Default mfa masked phone number
     * @type {String}
     */
    mfaPhoneNumber() {
      return maskPhoneNumber(this.primaryMfaDetail?.phoneNumber ?? "");
    },
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    /**
     * Handles otp verification of the user
     * @param {String} otp OTP enetered by the uset
     */
    async verifyOtp(otp) {
      this.otp = otp;
      this.enrollMultiFactor();
    },
    /**
     * Initialises the Multi Factor authentication of th euser
     */
    async initiateMfa() {
      await this.verifyRecaptchaVerifier();
      await this.enrollMultiFactor();
    },
    /**
     * Sets is loading state to show the loader to the end user
     * @param {Boolean} val Boolean value of isLoading property
     */
    setIsLoading(val) {
      this.isLoading = val;
    },
    /**
     * Verify user verification code code
     * @description Ask user for the verification code.
     */
    async verifySmsCode() {
      try {
        this.setIsLoading(true);

        var cred = PhoneAuthProvider.credential(
          this.smsVerificationId,
          this.otp
        );
        var multiFactorAssertion =
          PhoneMultiFactorGenerator.assertion(cred);

        // Complete enrollment. This will update the underlying tokens
        // and trigger ID token change listener.
        await this.authResolver.resolveSignIn(multiFactorAssertion);

        await this.showSuccessMessage();
        // await this.setCurrentUserDetails(userDetails.user);
      } catch (error) {
        this.showError(error);
      } finally {
        this.setIsLoading(false);
      }
    },
    /**
     * Computes the error message of the error encountered in the firebase service
     * @param {Error} error Error object
     */
    showError(error) {
      const message = AUTH_ERROR_CASE_MESSAGES[error?.code] ?? error?.message;
      this.errorMessage = message;
    },
    /**
     * Verifies and displayes recaptcha
     */
    async verifyRecaptchaVerifier() {
      if (this.isRecaptchaRendered) return;

      try {
        this.setIsLoading(true);

        this.recaptchaVerifier = new RecaptchaVerifier(auth,
          this.recaptchaContainer,
          {
            size: "invisible",
            callback: (response) => {
              // reCAPTCHA solved, you can proceed with phoneAuthProvider.verifyPhoneNumber(...).
              this.recaptchaVerified = !!response;
            },
          }
        );

        await this.recaptchaVerifier.render();
      } catch (error) {
        this.isRecaptchaRendered = false;
        this.showError(error);
      } finally {
        this.isRecaptchaRendered = true;
        this.setIsLoading(false);
      }
    },
    /**
     * Enrolls a new user phone number as Multi factor authentication
     * @listens submit Handles form submition and user phone verification and mutlti factor activation
     */
    async enrollMultiFactor() {
      try {
        this.setIsLoading(true);

        if (!this.smsVerificationId) {
          await this.sendVerificationMessage();
        } else if (this.otp) {
          await this.verifySmsCode();
        }
      } catch (error) {
        this.showError(error);
      } finally {
        this.setIsLoading(false);
      }
    },
    /**
     * Sends verification code to user phone number in the form
     */
    async sendVerificationMessage() {
      try {
        this.setIsLoading(true);
        var phoneAuthProvider = new PhoneAuthProvider(auth);

        // Specify the phone number and pass the MFA session.
        const phoneInfoOptions = {
          multiFactorHint: this.multiFactorDetails,
          session: this.authResolver.session,
        };
        // Send SMS verification code.
        this.smsVerificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          this.recaptchaVerifier
        );
      } catch (error) {
        this.showError(error);
      } finally {
        this.setIsLoading(false);
      }
    },
  },
  /**
  |--------------------------------------------------
  | Destroyed lilfecycle hook
  |--------------------------------------------------
  */
  destroyed() {
    !isEmpty(this.recaptchaVerifier) && this.recaptchaVerifier?.clear();
  },
};
