<template>
  <div id="reauthenticate-user-form">
    <div :id="recaptchaContainer" />
    <v-card class="pa-8" v-if="!askOtp">
      <div id="reauthenticate-user-form__content">
        <v-row>
          <v-col cols="12" lg="12" md="12">
            <h2 class="text-h6 text--primary font-weight-large secondary--font">
              {{ title }}
            </h2>

            <p class="body-2 text--secondary py-4 text-left mb-0">
              {{ subTitle }}
            </p>
          </v-col>
        </v-row>
        <v-form
          ref="form"
          v-model="isFormValid"
          @submit.prevent="submitHandler"
        >
          <alert-message v-if="!!errorMessage" :message="errorMessage" />
          <password-input-field
            is-required
            label="Password"
            :password="password"
            :rules="[required('Password')]"
            @update-password="password = $event"
          />

          <v-row no-gutters>
            <v-col cols="12" lg="12" md="12" class="d-flex justify-end">
              <v-btn
                text
                @click="onCancel"
                class="font-weight-bold secondary--font mr-1"
              >
                Cancel
              </v-btn>
              <v-btn
                type="submit"
                color="dark-black"
                :loading="isLoading"
                :disabled="!isFormValid"
                class="font-weight-bold secondary--font white--text"
              >
                Next
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-card>
    <v-card
      v-else-if="askOtp && isTextBasedMfaEnabled"
      class="pa-8"
      id="reauthenticate-user-form__enter-otp"
    >
      <enter-otp-form
        :heading="title"
        prepend-text="confirm changes."
        @submit="verifyOtp"
        :error-message="errorMessage"
        :phone-number="mfaPhoneNumber"
        :is-loading="isLoading"
        @reauthenticate="onCancel"
        text-field-class="pr-2 pr-sm-4"
        show-resend-code-option
        :is-code-sending="isCodeSending"
        :is-resend-code-allowed="isResendCodeAllowed"
        :resendVerificationCode="resendVerificationCode"
        resend-code-button-classes="mt-3"
      >
        <template #subHeading>
          <p class="body-2 text--secondary pt-3 text-left mb-0">
            Enter the 6-digit verification code sent to your current phone ({{
              maskedPhoneNumber
            }}) via text message to change MFA method.
          </p>
        </template>
        <template #buttons>
          <v-row>
            <v-col class="py-1" align="end">
              <delete-action-buttons
                btn-text="Submit"
                btn-type="submit"
                :is-deleting="isLoading"
                @close="onCancel"
              />
            </v-col>
          </v-row>
        </template>
      </enter-otp-form>
    </v-card>
  </div>
</template>

<script>
import { required } from "@/validators/form-validators";
import AlertMessage from "@/components/shared/AlertMessage.vue";
import { auth } from "@/services/auth";
import { getMultiFactorResolver } from 'firebase/auth' 
import { mapActions, mapGetters } from "vuex";
import {
  AUTH_ERROR_CASE_MESSAGES,
  AUTH_GIP_ERROR_CODES,
} from "@/constants/app";

import MfaAuthenticatorMixin from "@/mixins/MfaAuthenticator.mixin";
import ResendFirebaseTextCodeMixin from "@/mixins/ResendFirebaseTextCode.mixin";
import PasswordInputField from "@/components/shared/PasswordInputField.vue";

import { maskPhoneNumber } from "@/utils";
import EnterOTPForm from "./EnterOTPForm.vue";
import DeleteActionButtons from "@/components/shared/DeleteActionButtons.vue";

/**
 * Reauthenticate current user with password
 */
export default {
  name: "ReauthenticateUserForm",
  /**
  |--------------------------------------------------
  | Mixins
  |--------------------------------------------------
  */
  mixins: [MfaAuthenticatorMixin, ResendFirebaseTextCodeMixin],
  /**
  |--------------------------------------------------
  | Custom Events
  |--------------------------------------------------
  */
  emits: ["cancel", "reauthenticated"],
  /**
  |--------------------------------------------------
  | Props
  |--------------------------------------------------
  */
  props: {
    width: { type: String, default: "500px" },
    title: { type: String, default: "", required: true },
    subTitle: { type: String, default: "", required: true },
  },
  /**
  |--------------------------------------------------
  | Components
  |--------------------------------------------------
  */
  components: {
    AlertMessage,
    PasswordInputField,
    DeleteActionButtons,
    "enter-otp-form": EnterOTPForm,
  },
  /**
  |--------------------------------------------------
  | Data properties
  |--------------------------------------------------
  */
  data() {
    return {
      isLoading: false,
      isFormValid: false,
      password: "",
      errorMessage: "",
      askOtp: false,
      authResolver: null,
      isUserReauthenticated: false,
      recaptchaContainer: "recaptcha-mfa-container",
    };
  },
  /**
  |--------------------------------------------------
  | Computed properties
  |--------------------------------------------------
  */
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      primaryMfaDetail: "auth/primaryMfaDetail",
      isTextBasedMfaEnabled: "auth/isTextBasedMfaEnabled",
    }),
    maskedPhoneNumber() {
      return maskPhoneNumber(this.primaryMfaDetail?.phoneNumber ?? "");
    },
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    ...mapActions({
      setSnackbar: "ui/setSnackbar",
      reauthenticateUser: "auth/reauthenticateUser",
    }),
    required,
    /**
     * onCancel
     *  @emits cancel event in parent component
     */
    onCancel(event) {
      this.$emit("cancel", event);
    },
    /**
     * reauthenticated
     * @param {Boolean} val Is user re-authenticate or not
     * @emits reauthenticated event in parent component
     * @description Determines that user have been reauthenticated or not
     */
    reauthenticated(val) {
      this.$emit("reauthenticated", val);
    },
    /**
     * Form submit handler
     * @listens submit
     */
    async submitHandler() {
      try {
        this.isLoading = true;
        this.errorMessage = "";

        const { email } = this.currentUser;
        await this.reauthenticateUser({ email, password: this.password });

        this.reauthenticated(true);
        this.askOtp = false;
      } catch (error) {
        this.mfaErrorHandler(error);
      } finally {
        this.isLoading = false;
      }
    },
    errorHandler(error) {
      this.mfaErrorHandler(error);
    },
    /**
     * showSuccessMessage
     * Handles success process if user gets successfully re authenticated
     */
    showSuccessMessage() {
      this.isUserReauthenticated = true;
      this.reauthenticated(true);
    },
    /**
     * mfaErrorHandler
     * @description Ask user to enter OTP sent to his/her phone number
     */
    async mfaErrorHandler(error = {}) {
      try {
        const { code = "", message = error?.message } = error;
        if (code === AUTH_GIP_ERROR_CODES.mfaRequired) {
          this.authResolver = getMultiFactorResolver(auth, error);
          this.askOtp = true;
          await this.initiateMfa();
          this.resetResendCodeVerificationTimer();
        } else {
          const overrideMessages = {
            "auth/wrong-password": "Invalid Password",
            "auth/invalid-password": "Invalid Password",
          };
          this.errorMessage = error?.code
            ? { ...AUTH_ERROR_CASE_MESSAGES, ...overrideMessages }[error?.code]
            : message;
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
