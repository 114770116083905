<template>
  <div id="user-security-details">
    <v-row id="user-security-information" class="pt-5" no-gutters>
      <v-col cols="12">
        <v-row class="ml-3">
        <v-col cols="12" lg="9">
          <h1 class="text-h5 secondary--font font-weight-large">Security</h1>
        </v-col>
      </v-row>
        <change-password-form />
        <v-divider class="my-8 border-light-grey" />

        <v-row class="d-flex justify-center pl-4" no-gutters>
          <v-col cols="12" lg="12" md="12">
            <h1 class="subtitle-1 secondary--font font-weight-large">
              Multi-Factor Authentication
            </h1>
            <div class="text--secondary body-1 py-3">
              Maropost requires Multi-Factor Authentication for your account to
              improve security. You will be asked to provide two forms of
              identification when logging in - your password and an
              authentication code. Authentication code can be generated via text
              message or a security app such as Microsoft Authenticator.
            </div>
          </v-col>
        </v-row>
        <v-row
          no-gutters
          id="current-user-details"
          class="w-100 d-flex pl-4"
          align="start"
        >
          <v-col cols="12" lg="10" md="10" class="pt-2 d-flex justify-start">
            <v-radio-group
              id="mfa-options"
              class="mt-1"
              v-model="selectedMfaOption"
            >
              <v-radio
                :key="textBasedMfaOption.name"
                :label="textBasedMfaOption.name"
                :value="textBasedMfaOption.value"
                v-track="textBasedMfaOption.identifier"
              >
                <template #label>
                  <div class="pt-2">
                    <div class="text--primary subtitle-1">
                      {{ textBasedMfaOption.name }}
                    </div>
                    <div class="text--secondary body-2 pt-1">
                      You will receive a 6-digit code via SMS when you login.
                    </div>

                    <v-text-field
                      disabled
                      outlined
                      hide-details="auto"
                      label="Phone Number"
                      v-model="textBasedMfaNumber"
                      class="user-mfa-phonenumber pt-2 required"
                    >
                      <template #append>
                        <v-icon
                          v-text="'mdi-pencil'"
                          :disabled="!isTextBasedMfaEnabled"
                          class="cursor-pointer text--secondary"
                          v-track="'update-mfa-phone-number-icon-btn'"
                          :class="{
                            'cursor-not-allowed pointer-all-events':
                              !isTextBasedMfaEnabled,
                          }"
                          @click="changeMfaPhoneDialog = !changeMfaPhoneDialog"
                        />
                      </template>
                    </v-text-field>
                  </div>
                </template>
              </v-radio>
              <v-radio
                :key="authenticatorBasedMfaOption.name"
                :label="authenticatorBasedMfaOption.name"
                :value="authenticatorBasedMfaOption.value"
                v-track="textBasedMfaOption.identifier"
              >
                <template #label>
                  <div class="pt-2">
                    <div class="text--primary subtitle-1">
                      {{ authenticatorBasedMfaOption.name }}
                    </div>
                    <div class="text--secondary body-2">
                      Your security app (such as Microsoft Authenticator) will
                      generate a 6-digit code when you login.
                    </div>
                  </div>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-col>
     
    </v-row>
    <enforce-mfa-widget
      :change-text-based-mfa="changeMfaPhoneDialog"
      :text-based-mfa-dialog="showtextBasedMfaDialog"
      @toogle-change-mfa-dialog="changeMfaPhoneDialog = $event"
      @toogle-text-based-mfa-dialog="toogleTextBasefMfaDialog($event)"
    />
    <dialog-box
      width="500px"
      v-if="setupAuthenticatorAppDialog"
      :dialog="setupAuthenticatorAppDialog"
      @close="toogleAuthenticatorAppDialog"
    >
      <reauthenticate-user-form
        v-if="!isReauthenticated"
        @cancel="toogleAuthenticatorAppDialog(false)"
        @reauthenticated="onReauthenticated"
        title="Change Multi-Factor Authentication Method to Security App"
        sub-title="In order to change your Multi-Factor Authentication settings, please enter your password to continue."
      />
      <v-card v-else class="pa-8 h-100">
        <authenticator-app-mfa-setup-form
          header-class="text-h6"
          title="Change Multi-Factor Authentication Method to Security App"
          subtitle="You are about to change your Multi-Factor Authentication settings to Authenticator App. In order to setup:"
        >
          <template #instruction_2>
            <div class="barcode__wrapper">
              <template v-if="!!barcodeImage && !!secretCode">
                <div class="d-flex justify-center pb-4 barcode-image__wrapper">
                  <img
                    width="160px"
                    height="160px"
                    :src="barcodeImage"
                    alt="Security App Barcode"
                    class="authenticator-app-barcode__image"
                  />
                </div>
                <div
                  class="caption text--primary my-4 text-center text-capitalize"
                >
                  {{ secretCode }}
                </div>
              </template>
            </div>
          </template>
          <template #footer>
            <enter-otp-form
              phone-number=""
              :show-header="false"
              text-field-class="pr-2 pr-sm-3"
              @submit="onSubmitHandler"
              :error-message="errorMessage"
              @form-status="isFormValid = $event"
              @reauthenticate="toogleAuthenticatorAppDialog"
            >
              <template #buttons>
                <v-row class="no-gutters">
                  <v-col cols="12" class="d-flex justify-end mt-4">
                    <v-btn
                      text
                      type="button"
                      @click="toogleAuthenticatorAppDialog(false)"
                      class="
                        text--primary
                        font-weight-bold
                        secondary--font
                        mr-2
                      "
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      type="submit"
                      color="dark-black"
                      :loading="isLoading"
                      :disabled="!isFormValid"
                      class="white--text secondary--font font-weight-bold"
                    >
                      Submit
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </enter-otp-form>
          </template>
        </authenticator-app-mfa-setup-form>
      </v-card>
    </dialog-box>
  </div>
</template>
<script>
import {
  MFA_OPTIONS,
  RESPONSE_CODES,
  AUTH_ERROR_CASE_MESSAGES,
} from "@/constants/app";
import DialogBox from "@/components/shared/DialogBox.vue";
import EnterOTPForm from "@/components/forms/EnterOTPForm.vue";

import { isSuccess, isType } from "@/utils";
import { mapActions, mapGetters } from "vuex";

import ChangePasswordForm from "@/components/forms/ChangePasswordForm.vue";
import { disablePrimaryMfa, getAuthenticatorAppBarcode } from "@/services/auth";

import EnforceMfaWidget from "@/views/Dashboard/EnabledMfaWidget.vue";
import ReauthenticateUserForm from "@/components/forms/ReauthenticateUserForm.vue";
import AuthenticatorAppMfaSetupForm from "@/components/forms/AuthenticatorAppMfaSetupForm.vue";

/**
 * User details details component
 */
export default {
  name: "UserSecurityDetails",
  /**
  |--------------------------------------------------
  | Props
  |--------------------------------------------------
  */
  props: {},
  /**
  |--------------------------------------------------
  | Components
  |--------------------------------------------------
  */
  components: {
    AuthenticatorAppMfaSetupForm,
    DialogBox,
    "enter-otp-form": EnterOTPForm,
    ChangePasswordForm,
    ReauthenticateUserForm,
    EnforceMfaWidget,
  },
  /**
  |--------------------------------------------------
  | Custom events
  |--------------------------------------------------
  */
  emits: ["toogle-mfa", "disable-mfa", "enroll-text-message-mfa"],
  /**
  |--------------------------------------------------
  | Data Properties
  |--------------------------------------------------
  */
  data() {
    return {
      isLoading: false,
      isReauthenticated: false,
      showtextBasedMfaDialog: false,
      changeMfaPhoneDialog: false,
      isFormValid: false,
      selectedMfaOption: "",
      barcodeImage: "",
      errorMessage: "",
      successMessage: "",
      mfaOptions: [...MFA_OPTIONS],
      setupAuthenticatorAppDialog: false,
      password: {
        new: "",
        old: "",
      },
      secretCode: "",
    };
  },
  /**
  |--------------------------------------------------
  | Watching properties
  |--------------------------------------------------
  */
  watch: {
    /**
     * Watches selectedMfaOption property and computes the value
     */
    selectedMfaOption: {
      handler(val) {
        const isSelectionDifferent = this.enrolledMfaDetails?.value !== val;
        if (isSelectionDifferent) {
          this.isReauthenticated = false;
          if (this.authenticatorBasedMfaOption.value === val) {
            this.toogleAuthenticatorAppDialog(true);
            this.isReauthenticated && this.fetchAuthenticatorAppBarcode();
          } else if (this.textBasedMfaOption.value === val) {
            this.showtextBasedMfaDialog = true;
          }
        }
      },
    },
    /**
     * Watches enrolledMfaDetails store property to sync it with
     * component {{selectedMfaOption}} data property
     */
    enrolledMfaDetails: {
      immediate: true,
      handler() {
        this.selectedMfaOption = this.enrolledMfaDetails?.value;
      },
    },
    setupAuthenticatorAppDialog(val) {
      this.updateSelectedMfaOption(val);
    },
  },
  /**
  |--------------------------------------------------
  | Computed properties
  |--------------------------------------------------
  */
  computed: {
    ...mapGetters({
      token: "auth/token",
      currentUser: "auth/currentUser",
      userProfile: "auth/userProfile",
      primaryMfaDetail: "auth/primaryMfaDetail",
      enrolledMfaDetails: "auth/enrolledMfaDetails",
      isTextBasedMfaEnabled: "auth/isTextBasedMfaEnabled",
      isAuthenticatorAppMfaEnabled: "auth/isAuthenticatorAppMfaEnabled",
    }),
    /**
     * textBasedMfaOption
     * @description Text based MFA option details
     */
    textBasedMfaOption() {
      return this.mfaOptions[0];
    },
    /**
     * authenticatorBasedMfaOption
     * @description Authenticator based MFA option details
     */
    authenticatorBasedMfaOption() {
      return this.mfaOptions[1];
    },
    /**
     * textBasedMfaNumber
     * @description Phone number in which text based MFA have been setup
     */
    textBasedMfaNumber() {
      return this.primaryMfaDetail?.phoneNumber ?? "";
    },
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    ...mapActions({
      setSnackbar: "ui/setSnackbar",
      updateUserSession: "auth/updateUserSession",
      fetchRealtimeToken: "auth/fetchRealtimeToken",
      setCurentUserMfaDetails: "auth/setCurentUserMfaDetails",
      getUserProfileDetails: "auth/getUserProfileDetails",
      verifyAndSetupAuthenticatorAppMfa:
        "auth/verifyAndSetupAuthenticatorAppMfa",
    }),
    /**
     * toogleTextBasefMfaDialog
     * @description Toogles text base mfa setup dialog
     */
    toogleTextBasefMfaDialog(val = false) {
      this.showtextBasedMfaDialog = val;
      this.updateSelectedMfaOption(val);
    },
    /**
     * updateSelectedMfaOption
     * @description Updates and syncs the selected mfa radio button selection
     */
    updateSelectedMfaOption(val) {
      this.$nextTick(() => {
        if (!val && isType(val, "boolean")) {
          this.selectedMfaOption = this.enrolledMfaDetails?.value;
        }
      });
    },
    /**
     * onReauthenticated
     * @description Listens to the authenticated event
     */
    onReauthenticated(val) {
      this.isReauthenticated = val;
      val && this.fetchAuthenticatorAppBarcode();
    },
    /**
     * Closes snackbar
     */
    closeSnackbar() {
      this.errorMessage = "";
      this.successMessage = "";
    },
    /**
     * @emits disable-mfa to disable the primary mfa enabled by the user
     */
    onShowMfaDialog() {
      this.$emit("disable-mfa");
    },
    /**
     * Toogles authenticator app setup dialog box
     */
    async toogleAuthenticatorAppDialog(val = false) {
      this.setupAuthenticatorAppDialog = val;

      // Syncs the user profile details with the Firestore
      if (!val && !this.isAuthenticatorAppMfaEnabled && this.barcodeImage) {
        await this.getUserProfileDetails(this.userProfile);
      }
    },
    /**
     * Fetches Authenticator app setup barcode image
     */
    async fetchAuthenticatorAppBarcode() {
      try {
        this.isLoading = true;
        this.closeSnackbar();

        await this.fetchRealtimeToken();
        const { data } = await getAuthenticatorAppBarcode(this.token);
        this.barcodeImage = `data:image/png;base64, ${data?.imageData}`;
        this.secretCode = data?.secret ?? "";
      } catch (error) {
        this.errorMessage = error?.response?.data?.errors ?? error?.message;
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * Compute and show error message to the user
     * @param {Error} error
     */
    showErrorMessage(error) {
      this.errorMessage = error?.message;
      this.setSnackbar({
        value: true,
        message: this.errorMessage,
        type: this.$appConfig.snackbar.snackbarTypes.error,
      });
    },
    /**
     * Disables text base mfa of the user
     * @param {String} code Otp enetered bu the user
     */
    async disablePrimarySmsMfa(code) {
      try {
        const payload = { removeCookieFirst: true, code };

        await disablePrimaryMfa(this.primaryMfaDetail);
        await this.updateUserSession(payload);
      } catch (error) {
        this.isLoading = false;
        return Promise.reject(error);
      }
    },
    /**
     * Form submit handler
     * @listens submit
     */
    async onSubmitHandler(code) {
      try {
        this.isLoading = true;

        const status = await this.verifyAndSetupAuthenticatorAppMfa({ code });
        if (isSuccess(status)) {
          await this.disablePrimarySmsMfa(code);
          await Promise.allSettled([
            this.setCurentUserMfaDetails(),
            this.getUserProfileDetails(this.userProfile),
          ]);
          this.closeSnackbar();
          this.setSnackbar({
            value: true,
            message: `Multi-Factor Authentication method was successfully changed to security app.`,
            type: this.$appConfig.snackbar.snackbarTypes.success,
          });
          this.toogleAuthenticatorAppDialog();
        }
      } catch (error) {
        const isTotpInvalid =
          error?.response?.status === RESPONSE_CODES.unProcessed;
        if (isTotpInvalid) {
          this.errorMessage =
            AUTH_ERROR_CASE_MESSAGES["auth/invalid-totp-code"];
        } else {
          this.errorMessage = error?.response?.data?.errors ?? error?.message;
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
#mfa-options {
  .v-radio {
    display: flex;
    align-items: flex-start;

    .v-input--selection-controls__input {
      margin-top: 10px !important;
    }
    .v-label.theme--light {
      cursor: default !important;
    }
  }
}
</style>
