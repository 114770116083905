<template>
  <div id="change-password__form" class="ml-5">
    <div :id="recaptchaContainer" v-if="enterOtp" />
    <v-row class="d-flex justify-center mt-10">
      <v-col cols="12" lg="12" md="12">
        <h1 class="subtitle-1 secondary--font font-weight-large">
          Change Password
        </h1>
        <div class="text--secondary body-1 pt-1 pb-3">
          To ensure your information is secure, please choose a password that is
          unique and difficult to guess.
        </div>
      </v-col>
    </v-row>

    <v-form
      ref="changePasswordForm"
      v-model="isFormValid"
      @submit.prevent="submitHandler"
    >
      <v-row>
        <v-col sm="6" cols="12" md="6" lg="6">
          <password-input-field
            is-required
            is-hint-persistent
            label="Current password"
            :password="formData.currentPassword"
            @update-password="updatePassword($event, 'currentPassword')"
            hint="We need your current password to confirm your changes"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="6" cols="12" md="6" lg="6" class="py-1 pb-1">
          <password-input-field
            is-required
            label="New password"
            hide-details="auto"
            :rules="[isNewPasswordValid]"
            :password="formData.password"
            @update-password="updatePassword($event, 'password')"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col sm="6" cols="12" md="6" lg="6" class="py-1 pt-4 pb-4">
          <password-input-field
            is-required
            is-hint-persistent
            hide-details="auto"
            label="Confirm password"
            :password="formData.confirmPassword"
            :rules="[validateConfirmPassword()]"
            @update-password="updatePassword($event, 'confirmPassword')"
          />
        </v-col>
      </v-row>

      <password-rules-renderer
        wrapper-class="pt-2"
        :get-icon-color="getIconColor"
        :password-rules="passwordRules"
        :is-new-password-dirty="isNewPasswordDirty"
      />

      <v-row>
        <v-col cols="12" class="pt-9">
          <v-btn
            type="submit"
            color="dark-black"
            :loading="showLoader"
            :disabled="!isFormValid"
            class="font-weight-bold white--text"
            v-track="'change-password-submit-btn'"
          >
            Save changes
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <dialog-box
      width="500px"
      v-if="showMfaDialog"
      :dialog="showMfaDialog"
      @close="showMfaDialog = false"
    >
      <v-card class="pt-6 pb-6 px-6">
        <enter-otp-form
          heading="Multi-Factor Authentication Required"
          prepend-text="confirm changes."
          @submit="verifyOtp"
          :error-message="errorMessage"
          :phone-number="mfaPhoneNumber"
          text-field-class="pr-2 pr-sm-4"
          :is-loading="isLoading"
          @reauthenticate="reauthenticateHandler"
          show-resend-code-option
          :is-code-sending="isCodeSending"
          :is-resend-code-allowed="isResendCodeAllowed"
          :resend-verification-code="resendVerificationCode"
        >
          <template #buttons>
            <v-row>
              <v-col class="py-1" align="end">
                <delete-action-buttons
                  btn-text="Submit"
                  btn-type="submit"
                  :is-deleting="showLoader"
                  @close="closeDrawerAndDialog"
                  submit-btn-id="change-password-enter-otp-submit-btn"
                  cancel-btn-id="change-password-enter-otp-cancel-btn"
                />
              </v-col>
            </v-row>
          </template>
        </enter-otp-form>
      </v-card>
    </dialog-box>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { auth } from "@/services/auth";
import { getMultiFactorResolver } from 'firebase/auth' 
import { resetPassword } from "@/services/auth";
import PasswordInputField from "@/components/shared/PasswordInputField.vue";
import PasswordRulesRenderer from "@/components/shared/PasswordRulesRenderer.vue";

import { AUTH_GIP_ERROR_CODES } from "@/constants/app";
import MfaAuthenticator from "@/mixins/MfaAuthenticator.mixin";
import PasswordValidatorMixin from "@/mixins/PasswordValidator.mixin";

import EnterOTPForm from "./EnterOTPForm.vue";
import DialogBox from "../shared/DialogBox.vue";
import DeleteActionButtons from "../shared/DeleteActionButtons.vue";
import { defer } from "@/utils";

import { required } from "@/validators/form-validators.js";
import ResendFirebaseTextCodeMixin from "@/mixins/ResendFirebaseTextCode.mixin";

/**
 * Change password form
 */
export default {
  name: "ChangePassword",
  /**
   * -------------- Mixins --------------
   */
  mixins: [
    PasswordValidatorMixin,
    MfaAuthenticator,
    ResendFirebaseTextCodeMixin,
  ],
  /**
   * -------------- Components --------------
   */
  components: {
    PasswordInputField,
    PasswordRulesRenderer,
    DialogBox,
    "enter-otp-form": EnterOTPForm,
    DeleteActionButtons,
  },
  /**
   * Custom events emitted by the component
   */
  emits: ["toogle-drawer"],
  /**
   * -------------- Data Properties -------------
   */
  data() {
    return {
      isFormValid: false,
      formData: {
        currentPassword: "",
        password: "",
        confirmPassword: "",
      },
      isLoading: false,
      showMfaDialog: false,
      enterOtp: false,
    };
  },
  /**
   * -------------- Computed Properties -------------
   */
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    /**
     * Reauthenticate user payload
     */
    reauthenticateUserPayload() {
      return {
        email: this.currentUser?.email,
        password: this.formData.currentPassword,
      };
    },
    /**
     * Error messages
     * @description Fetched from the app dictionary
     */
    displayMessages() {
      return {
        passwordMisMatch: "New password and Confirm password must match.",
        passwordInvalid: "New password is not valid",
        currentPasswordInvalid: "Current password is not valid",
        passwordChangedSuccessfully: "Password changed successfully.",
      };
    },
    /**
     * showLoader
     * @description Shows loader in reset password button
     */
    showLoader() {
      return this.isLoading && !this.isCodeSending;
    },
  },
  /**
   * -------------- Methods --------------
   */
  methods: {
    required,
    ...mapActions({
      reauthenticateUser: "auth/reauthenticateUser",
      setSnackbar: "ui/setSnackbar",
    }),
    reauthenticateHandler() {
      this.showMfaDialog = false;
    },
    /**
     * Closes drawer and dialog simultaneously
     */
    closeDrawerAndDialog() {
      this.showMfaDialog = false;
      this.toogleDrawer(false);
    },
    /**
     * Shows success message when password have been reset
     */
    async showSuccessMessage() {
      // Resets the user password

      await resetPassword(this.formData.password);
      this.setSnackbar({
        value: true,
        message: this.displayMessages.passwordChangedSuccessfully,
        type: this.$appConfig.snackbar.snackbarTypes.success,
      });
      this.enterOtp = false;
      defer(() => this.$refs.changePasswordForm.reset(), 200);
      this.closeDrawerAndDialog();
    },
    errorHandler(error) {
      this.showError(error);
    },
    /**
     * Toogles drawer
     * @emits toogle-drawer
     */
    toogleDrawer(val) {
      this.$emit("toogle-drawer", val);
    },
    /**
     * Consumes the reset passwrd service and resets the user password
     * @description Updated user password with new one
     */
    async resetPassword() {
      try {
        this.isLoading = true;

        /**
         * Reauthenticates the user
         * @description this method throws error if mfa is enabled by the user
         * else user password gets updated with new one
         */
        await this.reauthenticateUser(this.reauthenticateUserPayload);

        // Resets the user password
        await resetPassword(this.formData.password);

        await this.showSuccessMessage();
        this.$refs.changePasswordForm.reset();
        this.toogleDrawer(false);
        this.enterOtp = false;
      } catch (error) {
        let { code, message } = error;

        // If mfa is enabled intialise mfa process
        if (code === AUTH_GIP_ERROR_CODES.mfaRequired) {
          this.authResolver = getMultiFactorResolver(auth, error);
          await (this.enterOtp = true);
          await this.initiateMfa();
          await this.resetResendCodeVerificationTimer();
          this.showMfaDialog = true;
        } else if (code === AUTH_GIP_ERROR_CODES.wrongPassword) {
          message = this.displayMessages.currentPasswordInvalid;
        }

        !this.showMfaDialog &&
          this.setSnackbar({
            value: true,
            message,
            type: this.$appConfig.snackbar.snackbarTypes.error,
          });
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * Handles password change form handler
     */
    submitHandler() {
      if (!this.isFormValid) {
        return this.$refs.changePasswordForm.validate();
      }
      this.resetPassword();
    },
    /**
     * Updates the password fields value
     * @param {String} value Updated value of the password field
     * @param {String} prop Model property name of password field to be updated
     */
    updatePassword(value, prop) {
      if (!prop) return;
      this.formData[prop] = value;
    },
  },
};
</script>
