<template>
  <div class="password-rules__list" :class="wrapperClass">
    <template v-for="{ text, isValid } in passwordRules">
      <v-row :key="text" no-gutters>
        <v-col cols="12" class="d-flex">
          <v-icon :color="getIconColor({ text, isValid })" small class="px-2">
            {{ isValid ? "mdi-check-circle" : "mdi-close-circle" }}
          </v-icon>
          <span
            :class="!isValid && isNewPasswordDirty ? 'red--text' : ''"
            class="caption py-1 text--secondary"
          >
            {{ text }}
          </span>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
/**
 * Password rule rendered
 * @author {Jatin Kamboj}
 */
export default {
  name: "PasswordRulesRenderer",
  /**
  |--------------------------------------------------
  | Props
  |--------------------------------------------------
  */
  props: {
    /**
     * Array of password rules to be rendered
     */
    passwordRules: { type: Array, required: true, default: () => [] },
    /**
     * Have user entered new password
     */
    isNewPasswordDirty: { type: Boolean, required: true, default: false },
    /**
     * Returns the color of password rules icon
     */
    getIconColor: { type: Function, required: true, default: () => {} },
    /**
     * Wrapper classes
     */
    wrapperClass: { type: String, default: "" },
  },
};
</script>
