<template>
  <div id="enable-mfa-widget">
    <!-- Handles enable text based mfa process of logged in user START -->
    <dialog-box
      v-if="showMfaDialog"
      :dialog="showMfaDialog"
      @close="onMfaDialogClose"
      width="500px"
    >
      <reauthenticate-user-form
        v-if="!isUserReauthenticated"
        @cancel="onMfaDialogClose(false)"
        @reauthenticated="onReauthenticated"
        title="Change Multi-Factor Authentication Method to Text Message"
        sub-title="In order to change your Multi-Factor Authentication settings, please enter your password to continue."
      />
      <enroll-multi-factor-form
        v-else
        @success="onSuccess"
        :show-header="false"
        :update-session="false"
        :reset-form="resetForm"
        :on-success="disableTotp"
        :captcha-wrapper-class="!isOtpSent ? 'mt-6' : ''"
        @otp-sent="isOtpSent = $event"
        @close-dialog="onMfaDialogClose"
        show-resend-code-option
        :wrapper-class="[{ 'pa-8': !isOtpSent }, 'mt-0']"
        otp-header-title="Change Multi-Factor Authentication Method to Text Message"
      >
        <template #header>
          <v-row class="mb-1">
            <v-col cols="12" lg="12">
              <h3
                class="
                  pb-4
                  text-h6 text--primary
                  secondary--font
                  font-weight-large
                "
              >
                Change Multi-Factor Authentication Method to Text Message
              </h3>
              <div class="text--secondary body-2 mb-2 enroll-mfa__description">
                <h5 class="text--secondary body-2">
                  You are about to change your Multi-Factor Authentication
                  settings to text message. In order to setup:
                </h5>
              </div>
            </v-col>
          </v-row>
        </template>
      </enroll-multi-factor-form>
    </dialog-box>
    <!-- Handles enable text based mfa process of logged in user END -->

    <!-- Handles change phone number of text based mfa process START -->
    <dialog-box
      width="500px"
      v-if="changeMfaPhoneDialog"
      :dialog="changeMfaPhoneDialog"
      @close="toogleMfaPhoneDialog"
    >
      <reauthenticate-user-form
        v-if="!isUserReauthenticated"
        @reauthenticated="onReauthenticated"
        @cancel="changeMfaPhoneDialog = false"
        title="Change Multi-Factor Authentication Phone Number"
        sub-title="In order to change your Multi-Factor Authentication settings, please enter your password to continue."
      />
      <enroll-multi-factor-form
        v-else
        :show-header="false"
        @success="removeOldMfa"
        :show-phone-info="false"
        :is-processing="isDisabling"
        prependText="confirm changes."
        @otp-sent="isOtpSent = $event"
        @close-dialog="toogleMfaPhoneDialog"
        :wrapper-class="[{ 'pa-8': !isOtpSent }, 'mt-0']"
        :captcha-wrapper-class="!isOtpSent ? 'mt-6' : ''"
        show-resend-code-option
        otp-header-title="Change Multi-Factor Authentication Phone Number"
      >
        <template #header>
          <v-row class="mb-1">
            <v-col cols="12" lg="12">
              <div class="text--secondary">
                <h3
                  class="
                    pb-4
                    text-h6 text--primary
                    secondary--font
                    font-weight-large
                  "
                >
                  Change Multi-Factor Authentication Phone Number
                </h3>
                <p class="mb-2 body-2">
                  You are about to change your phone number for text message
                  Multi-Factor Authentication.
                </p>
              </div>
            </v-col>
          </v-row>
        </template>
      </enroll-multi-factor-form>
    </dialog-box>
    <!-- Handles change phone number of text based mfa process END -->
  </div>
</template>

<script>
import { defer, isMobile, isType } from "@/utils";
import { mapActions, mapGetters } from "vuex";
import DialogBox from "@/components/shared/DialogBox.vue";

import { disableAuthenticatorAppMfa, disablePrimaryMfa } from "@/services/auth";
import EnrollMultiFactorForm from "@/components/forms/EnrollMultiFactorForm.vue";
import ReauthenticateUserForm from "@/components/forms/ReauthenticateUserForm.vue";

/**
 * Handles functionality to enable text based multi factor for an user
 * @author {Jatin Kamboj}
 */
export default {
  name: "EnableMfaWidget",
  /**
  |--------------------------------------------------
  | Components
  |--------------------------------------------------
  */
  components: { DialogBox, EnrollMultiFactorForm, ReauthenticateUserForm },
  /**
  |--------------------------------------------------
  | Props
  |--------------------------------------------------
  */
  props: {
    textBasedMfaDialog: { type: Boolean, default: false },
    changeTextBasedMfa: { type: Boolean, default: false },
  },
  /**
  |--------------------------------------------------
  | Data Properties
  |--------------------------------------------------
  */
  data() {
    return {
      isLoading: false,
      isDisabling: false,
      isOtpSent: false,
      resetForm: false,
      isUserReauthenticated: false,
    };
  },
  /**
  |--------------------------------------------------
  | Computed properties
  |--------------------------------------------------
  */
  computed: {
    ...mapGetters({
      token: "auth/token",
      userProfile: "auth/userProfile",
      primaryMfaDetail: "auth/primaryMfaDetail",
      isAuthenticatorAppMfaEnabled: "auth/isAuthenticatorAppMfaEnabled",
    }),
    showMfaDialog: {
      get() {
        return this.textBasedMfaDialog;
      },
      set(val) {
        this.$emit("toogle-text-based-mfa-dialog", val);
      },
    },
    changeMfaPhoneDialog: {
      get() {
        return this.changeTextBasedMfa;
      },
      set(val) {
        this.$emit("toogle-change-mfa-dialog", val);
      },
    },
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    ...mapActions({
      setSnackbar: "ui/setSnackbar",
      updateUserSession: "auth/updateUserSession",
      fetchRealtimeToken: "auth/fetchRealtimeToken",
      getUserProfileDetails: "auth/getUserProfileDetails",
      setCurentUserMfaDetails: "auth/setCurentUserMfaDetails",
    }),
    isMobile,
    /**
     * Closes enable mfa for the user dialog
     */
    onMfaDialogClose(event = false) {
      this.showMfaDialog = event;
      if (!event && isType(event, "boolean")) this.onReauthenticated(false);
    },
    async disableTotp() {
      await this.disableAuthenticatorAppMfa();
      await Promise.allSettled([
        this.updateUserSession(),
        this.getUserProfileDetails(this.userProfile),
      ]);
      this.isOtpSent = false;
    },
    showErrorMessage(error) {
      this.errorMessage = error?.message;
    },
    onReauthenticated(val) {
      this.isUserReauthenticated = val;
    },
    /**
     * Shows success message to user once mfa have been enabled succesfully
     */
    async onSuccess(phone) {
      if (!phone) return;

      this.showMfaDialog = false;
      // this.overrideSnackbar = true;
      this.setSnackbar({
        value: true,
        message: `Multi-Factor Authentication method was successfully changed to text message.`,
        type: this.$appConfig.snackbar.snackbarTypes.success,
      });
      // defer(() => this.closeSnackbar(), this.$appConfig.snackbar.timeout);
    },
    /**
     * Toogle mfa phone details dialog box
     * @param {Boolean} val Value to set in {{changeMfaPhoneDialog}} property
     */
    toogleMfaPhoneDialog(val) {
      this.changeMfaPhoneDialog = val;
      this.isOtpSent = false;
      defer(() => this.onReauthenticated(false), 500);
    },
    /**
     * Remove old text based mfa if new have been setup
     * @description displays success message after old MFA detail have been removed
     */
    async removeOldMfa() {
      try {
        this.isDisabling = true;

        if (this.primaryMfaDetail) {
          await disablePrimaryMfa(this.primaryMfaDetail);
          await Promise.allSettled([
            this.updateUserSession(),
            this.setCurentUserMfaDetails(),
            this.getUserProfileDetails(this.userProfile),
          ]);

          this.setSnackbar({
            value: true,
            message:
              "Phone number for text message Multi-Factor Authentication was successfully changed.",
            type: this.$appConfig.snackbar.snackbarTypes.success,
          });
          this.isOtpSent = false;
          this.toogleMfaPhoneDialog(false);
        }
      } finally {
        this.isDisabling = false;
      }
    },
    /**
     * Disable Authenticator app based Mfa TOTP
     */
    async disableAuthenticatorAppMfa() {
      try {
        this.isLoading = true;
        await this.fetchRealtimeToken();
        const { status } = await disableAuthenticatorAppMfa({
          idToken: this.token,
        });
        return status;
      } catch (error) {
        this.showErrorMessage(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
